import {betterToFixed} from './orders/DisplayCurrencyFormatted/DisplayCurrencyFormatted';

/**
 * Formats the number you specify as currency (price)
 * @param [num=0] - The number you wish to format as currency (price)
 * @param currency [string=""] - The currency label (eg. "CAD")
 * @returns {string} num formatted as currency as trusted HTML
 */
export function formatCurrency(num = 0, currency = ``): string {
    if (currency !== ``) {
        currency = currency + ` `;
    }
    if (num === null) {
        return `Pricing Error`;
    }
    if (num < 0) {
        return `<span style="color: #cc0000">${currency}($${betterToFixed(num)
            .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
            .replace('-', '')})</span>`;
    } else {
        return `${currency}$${betterToFixed(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}`;
    }
}
